import React from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const FeatureGrid = ({ gridItems }) => (
  <div className="tile is-ancestor is-flex-wrap-wrap p-2 m-2">
    {map(gridItems, item => (
      <div
        key={item.text}
        className="tile is-flex is-flex-direction-column is-4 pb-6 pr-4"
      >
        <div className="is-flex is-align-items-center">
          <PreviewCompatibleImage imageInfo={item} />
          <h4 className="is-flex is-align-items-center mb-0 pl-2">
            {item.title}
          </h4>
        </div>
        <p className="pl-2 ml-6">{item.text}</p>
      </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

export default FeatureGrid;
