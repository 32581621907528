import * as React from 'react';
import PropTypes from 'prop-types';

const Pricing = ({ data }) => (
  <div className="pricing">
    {data.map(price => (
      <div key={price.plan} className="plan has-background-link m-4">
        <div className="plan-header is-flex is-justify-content-center is-align-items-center pt-1">
          <span className="has-text-white is-size-4 is-size-5-mobile">
            {price.description}
          </span>
        </div>
        <div className="plan-body is-flex is-flex-direction-column">
          <div className="list-wrapper p-5 m-2">
            <ul className="has-text-white is-size-7">
              {price.items.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="price-wrapper">
            <h1 className="is-size-1 has-text-weight-bold has-text-centered has-text-white">
              ${price.price}
            </h1>
          </div>
          <div className="grab-wrapper">
            <button className="bttn violet">
              <div className="hover-flip">
                <span className="first">{price.plan}</span>
                <span className="second">{price.plan}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    ))}
  </div>
);

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
    }),
  ),
};

export default Pricing;
